import axios from "axios";
import appConfig from "configs/app.config";
import store from "../../store";
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import { jwtDecode } from "jwt-decode";

const Api = axios.create({
  timeout: 60000,
  baseURL: `${appConfig.apiPrefix}/api/v1`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
  withCredentials: true,
});

Api.interceptors.request.use(
  async (request) => {
    // Edit request config
    // console.log("interceptors run", request);
    const accessToken = localStorage.getItem("accessToken");
    // const accessToken = JSON.parse(localStorage.getItem("accessToken"));
    if (accessToken) {
      const decodeToken = jwtDecode(accessToken);
        const expireTime = new Date(decodeToken.exp * 1000);
        if (new Date() > expireTime) {
          console.log("Token expired");
          localStorage.removeItem(accessToken);
        } else {
          // console.log("Token valid", decodeToken);
          request.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
        }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Api.interceptors.request.use(
//   (config) => {
//     const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
//     const accessToken = JSON.parse(localStorage.getItem("accessToken"));
//     console.log("rawPersistData", rawPersistData);
//     console.log("token", accessToken);

//     if (accessToken) {
//       config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
//     }
//     console.log("request config", config);
//     return config;
//   },
//   (error) => {
//     // console.log("request error", error);
//     return Promise.reject(error);
//   }
// );

// BaseService.interceptors.request.use(
//   (config) => {
//     const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
//     const persistData = deepParseJson(rawPersistData);

//     let accessToken = persistData.auth.session.token;

//     if (!accessToken) {
//       const { auth } = store.getState();
//       accessToken = auth.session.token;
//     }

//     if (accessToken) {
//       config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
//     }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Api.interceptors.response.use(
//   (response) => {
//     console.log('interceptors res', response);
//     return response;
//   },
//   (error) => {
//     const { response } = error;

//     if (response && unauthorizedCode.includes(response.status)) {
//       store.dispatch(onSignOutSuccess());
//     }

//     return Promise.reject(error);
//   }
// );

export default Api;
