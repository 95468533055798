import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "services/api/Api";
import httpErrorHandler from "services/api/httpErrorHandler";

export const getVehicles = createAsyncThunk(
  "vehicle/getVehicles",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get(`/vehicles`);
      console.log("getVehicles", data.data);
      return fulfillWithValue(data);
    } catch (error) {
      // console.log(error.response.data);
      const errMsg = httpErrorHandler(error);
      return rejectWithValue(errMsg);
    }
  }
);

export const createVehicle = createAsyncThunk(
  "vehicle/createVehicle",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    try {
      const { data } = await Api.post(`/vehicles`, info);
      console.log("createVehicle", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("createVehicle error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("createVehicle errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const updateVehicle = createAsyncThunk(
  "vehicle/updateVehicle",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.patch(`/vehicles/${id}`, info);
      console.log("updateVehicle", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("updateVehicle error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("updateVehicle errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

const vehicleSlice = createSlice({
  name: "vehicle",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    vehicles: [],
    selectedVehicle: {},
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVehicles.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(getVehicles.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.error;
      })
      .addCase(getVehicles.fulfilled, (state, { payload }) => {
        state.loader = false;
        // state.successMessage = payload.message;
        state.vehicles = payload.data;
      })
      .addCase(createVehicle.pending, (state) => {
        state.loading = true;
      })
      .addCase(createVehicle.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(createVehicle.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedVehicle = payload.data;
      })
      .addCase(updateVehicle.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVehicle.rejected, (state, { payload }) => {
        console.log("payload", payload);
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(updateVehicle.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedProduct = payload.data;
      });
  },
});

export const { messageClear } = vehicleSlice.actions;
export default vehicleSlice.reducer;
