import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { setUser, initialState, onSignOutSuccess, messageClear, getRole, login, signup, logout } from "store/auth/userSlice";
// import { apiSignIn, apiSignOut, apiSignUp } from "services/AuthService";
// import { login, onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { Notification, toast } from "components/ui";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, userInfo, successMessage, errorMessage, signupSuccessMessage, signupErrorMessage } = useSelector((state) => state.auth.user);
  // const {} = useSelector((state) => {
  //   console.log('useAuth state', state)
  //   return state.user
  // });

  const signIn = async (values) => {
    dispatch(login(values));
  };

  useEffect(() => {
    dispatch(messageClear())
  }, [])
  

  useEffect(() => {
    if (successMessage) {
      // console.log("successMessage", successMessage);
      // type : ["info","success","warning","danger"]
      toast.push(
        <Notification title="Success" type="success" closable duration={2000}>
          Welcome back! {userInfo?.fullName}
        </Notification>,
        {
          placement: "top-end",
        }
      );
      dispatch(messageClear());
      // const redirectUrl = query.get(REDIRECT_URL_KEY);
      navigate(appConfig.authenticatedEntryPath);
    }

    if (errorMessage) {
      // console.log("errorMessage", errorMessage);
      toast.push(
        <Notification title="Error" type="danger"  closable duration={2000}>
          {errorMessage}. Please try again
        </Notification>,
        {
          placement: "top-end",
        }
      );
      dispatch(messageClear());
    }

  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (signupSuccessMessage) {
      // console.log("successMessage", signupSuccessMessage);
      // type : ["info","success","warning","danger"]
      toast.push(
        <Notification title="Sign up success" type="success" closable duration={2000}>
          {signupSuccessMessage}
        </Notification>,
        {
          placement: "top-end",
        }
      );
      dispatch(messageClear());
      // const redirectUrl = query.get(REDIRECT_URL_KEY);
      // navigate(appConfig.authenticatedEntryPath);
    }

    if (signupErrorMessage) {
      // console.log("errorMessage", errorMessage);
      toast.push(
        <Notification title="Sign up failed" type="danger"  closable duration={2000}>
          {signupErrorMessage}. Please try again.
        </Notification>,
        {
          placement: "top-end",
        }
      );
      dispatch(messageClear());
    }

  }, [signupErrorMessage, signupSuccessMessage]);


  const signUp = async (values) => {
    dispatch(signup(values));
    // try {
    //   const resp = await apiSignUp(values);
    //   if (resp.data) {
    //     const { token } = resp.data;
    //     dispatch(onSignInSuccess(token));
    //     if (resp.data.user) {
    //       dispatch(
    //         setUser(
    //           resp.data.user || {
    //             avatar: '',
    //             userName: 'Anonymous',
    //             authority: ['USER'],
    //             email: '',
    //           }
    //         )
    //       );
    //     }
    //     const redirectUrl = query.get(REDIRECT_URL_KEY);
    //     navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
    //     return {
    //       status: 'success',
    //       message: '',
    //     };
    //   }
    // } catch (errors) {
    //   return {
    //     status: 'failed',
    //     message: errors?.response?.data?.message || errors.toString(),
    //   };
    // }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    try {
      dispatch(logout())
    } catch (errors) {
      console.log('signout error', errors)
    }
  };

  const authenticated = () => {
    let result = ""
    if (token) {
      const decodeToken = jwtDecode(token);
      const expireTime = new Date(decodeToken.exp * 1000);
      if (new Date() > expireTime) {
        console.log("Token expired");
        localStorage.removeItem("accessToken");
        result = "";
      } else {
        // console.log("Token valid", decodeToken);
        result =  decodeToken.role;
      }
    } else {
      return "";
    }
    // console.log('authenticated??', result)
    return result;
  }

  return {
    authenticated: authenticated(),
    signIn,
    signUp,
    signOut,
    user: userInfo
  };
}

export default useAuth;
