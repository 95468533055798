import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "services/api/Api";
import httpErrorHandler from "services/api/httpErrorHandler";
import ScanHistory from "views/battery/BatteryDetails/components/ScanHistory";

export const getEndUsers = createAsyncThunk(
  "user/getEndUsers",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/users/endusers");
      console.log("getEndUsers", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getEndUsers error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getEndUsers errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/users");
      console.log("getUsers", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getUsers error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getUsers errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const getUserById = createAsyncThunk(
  "user/getUserById",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/users/" + info.id);
      console.log("getUserById", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getUserById error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getUserById errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const getUserScanHistory = createAsyncThunk(
  "user/getUserScanHistory",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/users/scan/" + info.id);
      console.log("getUserScanHistory", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getUserScanHistory error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getUserScanHistory errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const addUser = createAsyncThunk(
  "user/addUser",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    try {
      const { data } = await Api.post(`/users`, info);
      console.log("addUser", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("addUser error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("addUser errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.patch(`/users/${id}`, info);
      console.log("updateUser", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("updateUser error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("updateUser errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/updateUserInfo",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.post(`/users/update`, info);
      console.log("updateUserInfo", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("updateUserInfo error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("updateUserInfo errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    selectedUser: {},
    scanHistory: [],
    userList: [],
    endUserList: [],
    total: 0,
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEndUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEndUsers.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getEndUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.total = payload.total;
        state.endUserList = payload.data;
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.total = payload.total;
        state.userList = payload.data;
      })
      .addCase(getUserById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserById.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.selectedUser = payload.data;
      })
      .addCase(getUserScanHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserScanHistory.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getUserScanHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.scanHistory = payload.data;
        state.total = payload.total;
      })
      .addCase(addUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(addUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedUser = payload.data;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        console.log("payload", payload);
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedUser = payload.data;
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.rejected, (state, { payload }) => {
        console.log("payload", payload);
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedUser = payload.data;
      });
  },
});

export const { messageClear } = userSlice.actions;
export default userSlice.reducer;