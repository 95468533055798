import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "services/api/Api";
import httpErrorHandler from "services/api/httpErrorHandler";

export const getWarranties = createAsyncThunk(
  "warranty/getWarranties",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    console.log("getWarranties 🚀🚀🚀🚀🚀");
    try {
      const { data } = await Api.get('/warranties');
      console.log("getWarranties", data.data);
      return fulfillWithValue(data);
    } catch (error) {
      // console.log(error.response.data);
      const errMsg = httpErrorHandler(error);
      return rejectWithValue(errMsg);
    }
  }
);

// export const getWarrantyById = createAsyncThunk(
//   "warranty/getWarrantyById",
//   async ({ id }, { rejectWithValue, fulfillWithValue }) => {
//     try {
//       const { data } = await Api.get(`/battery/${id}`);
//       console.log("getWarrantyById", data);
//       return fulfillWithValue(data);
//     } catch (error) {
//       // console.log(error.response.data);
//       const errMsg = httpErrorHandler(error);
//       return rejectWithValue(errMsg);
//     }
//   }
// );

const warrantySlice = createSlice({
  name: "warranty",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    selectedWarranty: {},
    warranties: [],
    totalWarranty: 0
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWarranties.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(getWarranties.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload?.error;
      })
      .addCase(getWarranties.fulfilled, (state, { payload }) => {
        state.warranties = payload.data;
        state.totalWarranty = payload.total;
        state.loader = false;
        // state.successMessage = payload.message;
      })

      // .addCase(getWarrantyById.pending, (state, { payload }) => {
      //   state.loader = true;
      // })
      // .addCase(getWarrantyById.rejected, (state, { payload }) => {
      //   state.loader = false;
      //   // state.errorMessage = payload.error;
      // })
      // .addCase(getWarrantyById.fulfilled, (state, { payload }) => {
      //   state.selectedWarranty = payload.data;
      //   state.loader = false;
      //   // state.successMessage = payload.message;
      // })

  },
});

export const { messageClear } = warrantySlice.actions;
export default warrantySlice.reducer;
