import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "services/api/Api";
import httpErrorHandler from "services/api/httpErrorHandler";

export const getBattery = createAsyncThunk(
  "battery/getBattery",
  async (
    { perPage = 9999, page = 1, searchValue = "", sortKey = "lastUpdatedAt", sortDirection = "desc" },
    { rejectWithValue, fulfillWithValue }
  ) => {
    console.log("getBattery 🚀🚀🚀🚀🚀");
    try {
      const { data } = await Api.get(
        `/battery?s=${searchValue}&sort=${sortDirection}&key=${sortKey}&page=${page}&limit=${perPage}`
      );
      console.log("getBattery", data.data);
      return fulfillWithValue(data);
    } catch (error) {
      // console.log(error.response.data);
      const errMsg = httpErrorHandler(error);
      return rejectWithValue(errMsg);
    }
  }
);

export const getDeletedBattery = createAsyncThunk(
  "battery/getDeletedBattery",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/battery/discarded");
      console.log("getDeletedBattery", data.data);
      return fulfillWithValue(data);
    } catch (error) {
      // console.log(error.response.data);
      const errMsg = httpErrorHandler(error);
      return rejectWithValue(errMsg);
    }
  }
);

export const getBatteryById = createAsyncThunk(
  "battery/getBatteryById",
  async ({ id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get(`/battery/${id}`);
      console.log("getBatteryById", data);
      return fulfillWithValue(data);
    } catch (error) {
      // console.log(error.response.data);
      const errMsg = httpErrorHandler(error);
      return rejectWithValue(errMsg);
    }
  }
);

export const updateBattery = createAsyncThunk(
  "battery/updateBattery",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.patch(`/battery/${id}`, info);
      console.log("updateBattery", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("updateBattery error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("updateBattery errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const getQrCodeById = createAsyncThunk(
  "battery/getQrCodeById",
  async ({ id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get(`/battery/${id}/qrcode`);
      console.log("getQrCodeById", data);
      return fulfillWithValue(data);
    } catch (error) {
      // console.log(error.response.data);
      const errMsg = httpErrorHandler(error);
      return rejectWithValue(errMsg);
    }
  }
);

export const addBattery = createAsyncThunk(
  "battery/addBattery",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    try {
      const { data } = await Api.post(`/battery`, info);
      console.log("addBattery result", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("addBattery error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("addBattery errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

export const deleteBattery = createAsyncThunk(
  "battery/deleteBattery",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.patch(`/battery/${id}/discard`, info);
      console.log("deleteBattery result", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("deleteBattery error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("deleteBattery errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

export const restoreBattery = createAsyncThunk(
  "battery/restoreBattery",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.patch(`/battery/${id}/restore`, info);
      console.log("restoreBattery result", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("restoreBattery error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("restoreBattery errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

export const rechargeBattery = createAsyncThunk(
  "battery/rechargeBattery",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    // const { id } = info;
    try {
      const { data } = await Api.post(`/battery/recharge`, info);
      console.log("rechargeBattery", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("rechargeBattery error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("rechargeBattery errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

const batterySlice = createSlice({
  name: "battery",
  initialState: {
    delSuccessMessage: "",
    successMessage: "",
    errorMessage: "",
    updateSuccessMessage: "",
    updateErrorMessage: "",
    qrSuccessMessage: "",
    qrErrorMessage: "",
    loader: false,
    selectedBattery: {},
    batteries: [],
    newBatteries: [],
    totalBattery: 0,
    qrCodeBase64: "",
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.qrErrorMessage = "";
      state.qrSuccessMessage = "";
      state.delSuccessMessage = "";
      state.updateSuccessMessage = "";
      state.updateErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBattery.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(addBattery.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.error;
      })
      .addCase(addBattery.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = payload.message;
        state.newBatteries = payload.data;
        // state.totalBattery = payload.total;
      })

      .addCase(getBattery.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(getBattery.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload?.error;
      })
      .addCase(getBattery.fulfilled, (state, { payload }) => {
        state.batteries = payload.data;
        state.totalBattery = payload.total;
        state.loader = false;
        // state.successMessage = payload.message;
      })

      .addCase(getDeletedBattery.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(getDeletedBattery.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload?.error;
      })
      .addCase(getDeletedBattery.fulfilled, (state, { payload }) => {
        state.batteries = payload.data;
        state.totalBattery = payload.total;
        state.loader = false;
      })

      .addCase(getBatteryById.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(getBatteryById.rejected, (state, { payload }) => {
        state.loader = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getBatteryById.fulfilled, (state, { payload }) => {
        state.selectedBattery = payload.data;
        state.loader = false;
        // state.successMessage = payload.message;
      })
      .addCase(updateBattery.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(updateBattery.rejected, (state, { payload }) => {
        state.loader = false;
        state.updateErrorMessage = payload.error;
      })
      .addCase(updateBattery.fulfilled, (state, { payload }) => {
        state.selectedBattery = payload.data;
        state.loader = false;
        state.updateSuccessMessage = payload.message;
      })
      .addCase(getQrCodeById.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(getQrCodeById.rejected, (state, { payload }) => {
        state.loader = false;
        state.qrErrorMessage = payload.error;
      })
      .addCase(getQrCodeById.fulfilled, (state, { payload }) => {
        state.qrCodeBase64 = payload.data;
        state.qrSuccessMessage = payload.message;
        state.loader = false;
      })

      .addCase(deleteBattery.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(deleteBattery.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.error;
      })
      .addCase(deleteBattery.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.delSuccessMessage = payload.message;
        state.selectedBattery = payload.data;
      })
      .addCase(restoreBattery.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(restoreBattery.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.error;
      })
      .addCase(restoreBattery.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.delSuccessMessage = payload.message;
        state.selectedBattery = payload.data;
      })
      .addCase(rechargeBattery.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(rechargeBattery.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.error;
      })
      .addCase(rechargeBattery.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.delSuccessMessage = payload.message;
        state.selectedBattery = payload.data;
      });
  },
});
export const { messageClear } = batterySlice.actions;
export default batterySlice.reducer;
