import React from "react";
import { Card, Table } from "components/ui";

const ScanHistory = ({ data }) => {
  const { Tr, Th, Td, THead, TBody } = Table;
  return (
    <Card className="mb-4 text-xs">
      <h5 className="mb-4">Scan history</h5>
      <Table className="w-full text-xs text-left border-spacing-1">
        <THead className="text-xs uppercase border-b border-slate-700">
          <Tr>
            <Th scope="col" className="py-3 px-4">
              Scan Date
            </Th>
            <Th scope="col" className="py-3 px-4">
              Scan Mode
            </Th>
            <Th scope="col" className="py-3 px-4">
              Scan By
            </Th>
            <Th scope="col" className="py-3 px-4">
              Voltage
            </Th>
            <Th scope="col" className="py-3 px-4">
              Alternator Voltage
            </Th>
            <Th scope="col" className="py-3 px-4">
              CCA
            </Th>
            <Th scope="col" className="py-3 px-4">
              AGM
            </Th>
            {/* <Th scope="col" className="py-3 px-4">
              Log
            </Th> */}
          </Tr>
        </THead>

        <TBody>
          {data.map((b, i) => (
            <Tr key={b.id}>
              <Td scope="row" className="py-1 px-4 whitespace-nowrap ">
                {b.createdAt.split("T")[0]}
              </Td>
              <Td scope="row" className="py-1 px-4 whitespace-nowrap">
                {b.scanMode}
              </Td>
              <Td scope="row" className="py-1 px-4 whitespace-nowrap">
                {b.scannedByUserName}
              </Td>
              <Td scope="row" className="py-1 px-4 whitespace-nowrap">
                {b.voltage}
              </Td>
              <Td scope="row" className="py-1 px-4 whitespace-nowrap">
                {b.alternatorVoltage}
              </Td>
              <Td scope="row" className="py-1 px-4 whitespace-nowrap">
                {b.cca}
              </Td>
              <Td scope="row" className="py-1 px-4 whitespace-nowrap">
                {b.agm}
              </Td>
              {/* <Td scope="row" className="py-1 px-4 whitespace-nowrap text-artablue-400">
                {b.doNumber}
              </Td> */}
            </Tr>
          ))}
        </TBody>
      </Table>
    </Card>
  );
};

export default ScanHistory;
