import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import authReducer from "./auth";
import batteryReducer from "./battery";
import companyReducer from "./company";
import userReducer from "./user";
import base from "./base";
import locale from "./locale/localeSlice";
import brandReducer from "./brand";
import productReducer from "./product";
import deliveryOrderReducer from "./deliveryOrder";
import warrantyReducer from "./warranty";
import mainDashboardReducer from "./mainDashboard";
import vehicleReducer from "./vehicle";


const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth: authReducer,
    battery: batteryReducer,
    company: companyReducer,
    user: userReducer,
    brand: brandReducer,
    product: productReducer,
    deliveryOrder: deliveryOrderReducer,
    warranty: warrantyReducer,
    mainDashboard: mainDashboardReducer,
    vehicle: vehicleReducer,
    base,
    locale,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
