import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "services/api/Api";
import dayjs from "dayjs";
// import { apiGetSalesDashboardData } from "@/services/SalesService";

// type Statistic = {
//     value: number
//     growShrink: number
// }

// export type DashboardData = {
//     statisticData?: {
//         revenue: Statistic
//         orders: Statistic
//         purchases: Statistic
//     }
//     salesReportData?: {
//         series: {
//             name: string
//             data: number[]
//         }[]
//         categories: string[]
//     }
//     topProductsData?: {
//         id: string
//         name: string
//         img: string
//         sold: number
//     }[]
//     latestOrderData?: {
//         id: string
//         date: number
//         customer: string
//         status: number
//         paymentMehod: string
//         paymentIdendifier: string
//         totalAmount: number
//     }[]
//     salesByCategoriesData?: {
//         labels: string[]
//         data: number[]
//     }
// }

// type DashboardDataResponse = DashboardData

// export type SalesDashboardState = {
//     startDate: number
//     endDate: number
//     loading: boolean
//     dashboardData: DashboardData
// }

export const SLICE_NAME = "mainDashboard";

export const getMainDashboardData = createAsyncThunk(
  SLICE_NAME + "/getMainDashboardData",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    console.log("getMainDashboardData 🚀🚀🚀🚀🚀");
    try {
      const { data } = await Api.get("/deliveryorders");
      console.log("getMainDashboardData", data.data);
      return fulfillWithValue(data);
    } catch (error) {
      // console.log(error.response.data);
      const errMsg = httpErrorHandler(error);
      return rejectWithValue(errMsg);
    }
  }
);

const initialState = {
  startDate: dayjs(dayjs().subtract(3, "month").format("DD-MMM-YYYY, hh:mm A")).unix(),
  endDate: dayjs(new Date()).unix(),
  loading: true,
  errorMessage: "",
  successMessage: "",
  doData: [],
  dashboardData: {},
};

const mainDashboardSlice = createSlice({
  name: "mainDashboard",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMainDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMainDashboardData.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload?.error;
      })

      .addCase(getMainDashboardData.fulfilled, (state, { payload }) => {
        state.doData = payload.data;
        state.loading = false;
      });
  },
});

export const { setStartDate, setEndDate, messageClear } = mainDashboardSlice.actions;

export default mainDashboardSlice.reducer;
